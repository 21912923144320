var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-map"
  }, [_c('Card', [_c('p', {
    staticClass: "card-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("访问统计")]), _c('Row', [_c('Col', {
    attrs: {
      "span": "16"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "id": "map"
    }
  })]), _c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('Row', {
    staticClass: "right",
    attrs: {
      "type": "flex",
      "justify": "center",
      "align": "middle"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('progressItem', {
    attrs: {
      "value": "1,12,540",
      "title": "北京",
      "data": 72,
      "color": "#5089de"
    }
  }), _c('progressItem', {
    attrs: {
      "value": "51,480",
      "title": "上海",
      "data": 39,
      "color": "#23b397"
    }
  }), _c('progressItem', {
    attrs: {
      "value": "45,760 ",
      "title": "深圳",
      "data": 61,
      "color": "#56c2d6"
    }
  }), _c('progressItem', {
    attrs: {
      "value": "98,512",
      "title": "广州",
      "data": 52,
      "color": "#f8cc6b"
    }
  }), _c('progressItem', {
    attrs: {
      "value": "2,154",
      "title": "成都",
      "data": 28,
      "color": "#f0643b"
    }
  })], 1)])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };