export default {
  '海门': [121.15, 31.89],
  '鄂尔多斯': [109.781327, 39.608266],
  '招远': [120.38, 37.35],
  '舟山': [122.207216, 29.985295],
  '齐齐哈尔': [123.97, 47.33],
  '广州': [113.23, 23.16],
  '盐城': [120.13, 33.38],
  '赤峰': [118.87, 42.28],
  '深圳': [114.07, 22.62],
  '青岛': [120.33, 36.07],
  '北京': [116.46, 39.92],
  '乳山': [121.52, 36.89],
  '金昌': [102.188043, 38.520089],
  '泉州': [118.58, 24.93],
  '莱西': [120.53, 36.86],
  '日照': [119.46, 35.42],
  '胶南': [119.97, 35.88],
  '南通': [121.05, 32.08],
  '拉萨': [91.11, 29.97],
  '云浮': [112.02, 22.93],
  '梅州': [116.1, 24.55],
  '文登': [122.05, 37.2],
  '上海': [121.48, 31.22]
};