import * as echarts from 'echarts';
import progressItem from "./progress";
import geoData from "../../map-data/get-geography-value.js";
import cityData from "../../map-data/get-city-value.js";
var chinaJson = require("../../map-data/china.json");
export default {
  name: "mapCard",
  components: {
    progressItem
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    convertData(data) {
      let res = [];
      let len = data.length;
      for (var i = 0; i < len; i++) {
        var geoCoord = geoData[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          });
        }
      }
      return res;
    },
    init() {
      let map = echarts.init(document.getElementById("map"), null, {
        locale: "ZH"
      });
      echarts.registerMap("china", chinaJson);
      map.setOption({
        backgroundColor: "#FFF",
        toolbox: {
          show: true,
          left: "left",
          top: "top",
          feature: {
            dataView: {
              readOnly: false
            },
            restore: {},
            saveAsImage: {}
          }
        },
        geo: {
          map: "china",
          label: {
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              areaColor: "#EFEFEF",
              borderColor: "#CCC"
            },
            emphasis: {
              areaColor: "#E5E5E5"
            }
          }
        },
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          containLabel: true
        },
        series: [{
          type: "scatter",
          coordinateSystem: "geo",
          data: this.convertData(cityData),
          symbolSize: function (val) {
            return val[2] / 10;
          },
          label: {
            normal: {
              formatter: "{b}",
              position: "right",
              show: false
            },
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            normal: {
              color: "#63a3f9"
            }
          }
        }]
      });
      window.addEventListener("resize", function () {
        map.resize();
      });
    }
  },
  mounted() {
    this.init();
  }
};